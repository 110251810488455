import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import styles from '../styles/index.module.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  fragment SanityTtlImage on SanityTitleImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      titleImage{
            ...SanityTtlImage
          }
      description
      keywords
    }
    # posts: allSanityPost(
    #   limit: 6
    #   sort: { fields: [publishedAt], order: DESC }
    #   filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    # ) {
    #   edges {
    #     node {
    #       id
    #       publishedAt
    #       mainImage {
    #         ...SanityImage
    #         alt
    #       }
    #       title
    #       _rawExcerpt
    #       slug {
    #         current
    #       }
    #     }
    #   }
    # }

    featured: sanityFeatureList {
    featuredPost {
       title
          mainImage {
            ...SanityImage
            alt
          }
          slug {
            current
          }
          id
          publishedAt
        }
    title
    }

}`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  // const postNodes = (data || {}).posts
  //   ? mapEdgesToNodes(data.posts)
  //     .filter(filterOutDocsWithoutSlugs)
  //     .filter(filterOutDocsPublishedInTheFuture)
  //   : []

  const featuredNodes = (data || {}).featured.featuredPost
    ? data.featured.featuredPost
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  // console.log(postNodes)
  // console.log(data.featured.featuredPost)
  return (
    <Layout>
    {
      site.titleImage && site.titleImage.asset && (
        <div className={styles.titleImage}>
          <img
          src={imageUrlFor(buildImageObj(site.titleImage))
            .fit('crop')
            .width('1200')
            .auto('format')
            .url()}
          />
        </div>
    )
          }
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
      
        <p>{site.description}</p>
        <br/>
        <hr/>
        <br/>
        {featuredNodes && (
          <BlogPostPreviewGrid
            title={data.featured.title}
            nodes={featuredNodes}
            browseMoreHref='/artwork/'
          />
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
